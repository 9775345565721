import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar, Sort} from '@angular/material';
import SearchResponse from '../../../../../response/search.response';
import {InstallmentService} from '../../../../../services/installment/installment.service';
import {
  InstallmentsNotificationPopupComponent
} from '../../../../info-right-menu/modals/payment-link-v2/installments-notification-popup/installments-notification-popup.component';

@Component({
  selector: 'app-installment-modal',
  templateUrl: './installment-modal.component.html',
  styleUrls: ['./installment-modal.component.scss']
})
export class InstallmentModalComponent implements OnInit {

  public loader = false;

  public displayedColumns: string[] = ['lead_number', 'product', 'paid_amount', 'outstanding_amount', 'actions'];

  public installments: SearchResponse = new SearchResponse({
    page_size: 10
  });

  private timeout = null;

  public actions: any = [
    {
      label: 'Add',
      fn: (row) => {
        this.matDialog.open(InstallmentsNotificationPopupComponent, {
          minWidth: '500px',
          minHeight: '500px',
          data: {
            lead_number: row.lead_number,
            product: row.product,
            payment_amount: Number(row.paid_amount)
          }
        }).afterClosed().subscribe((result) => {
            if (result) {
              if (typeof result === 'string') {
                this.snackBar.open(result, 'Ok!', {
                  duration: 3000,
                });
              } else {
                this.getData();
              }
            }
          },
        );
      }
    }
  ];


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public self: MatDialogRef<InstallmentModalComponent>,
    private installmentService: InstallmentService,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.getData();
  }

  private getData(): void {
    this.loader = true;

    this.installmentService.getInstallments(this.installments.getReqData()).subscribe((res) => {
      this.installments = Object.assign(this.installments, res);

      this.loader = false;
    }, () => {
      this.loader = false;
    });
  }

  public changeSort(sortState: Sort): void {
    this.installments.addSort(sortState.active, sortState.direction);

    this.getData();
  }

  public changePage(event: any): void {
    this.installments.page = event.pageIndex;
    this.installments.page_size = event.pageSize;

    this.getData();
  }

  public applyFilter(filter): void {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.installments.addFilters(filter);

      this.getData();
    }, 500);
  }
}
