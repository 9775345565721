export function isSameDayOrLaterTs(ts1, ts2): boolean {
    const date1 = new Date(ts1 * 1000);
    const date2 = new Date(ts2 * 1000);

    // Check if both timestamps are on the same day
    const sameDay =
        date1.getUTCFullYear() === date2.getUTCFullYear() &&
        date1.getUTCMonth() === date2.getUTCMonth() &&
        date1.getUTCDate() === date2.getUTCDate();

    // Check if timestamp1 is later or equal
    return sameDay || ts1 >= ts2;
}