import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { User } from '../../../../services/users/user';
import { select, Store } from '@ngrx/store';
import { MatDialog, MatDrawer, MatSnackBar } from '@angular/material';
import { LevelModalComponent } from '../../../general/level-modal/level-modal.component';
import { Cookies } from '../../../../helpers/Cookies';
import { UserService } from '../../../../services/users/user.service';
import localStore from 'store';
import { CheckinService } from '../../../../services/checkin/checkin.service';
// import { AgentNotoficationModalComponent } from '../../../general/agent-notofication-modal/agent-notofication-modal.component';
import { PauseService } from '../../../../services/pause/pause.service';
import { PushNotificationsService } from 'ng-push';
import get from 'lodash/get';
import { CheckInManageComponent } from '../../../general/check-in-manage/check-in-manage.component';
import { UsersLoginPopupComponent } from './users-login-pop/users-login-popup.component';

import { environment } from '../../../../../environments/environment';
import { QuizService } from 'src/app/services/quiz/quiz.service';
import { QuizPopupComponent } from 'src/app/components/quiz-popup/quiz-popup.component';
import { CentrifugeService } from 'src/app/services/centrifuge/centrifuge.service';
import { quizTypes } from 'src/app/constants/quizzes';
import { quizEndStatus } from 'src/app/components/quiz-popup/constants/quizEndStatus';
import { ReferralsComponent } from 'src/app/components/general/referrals/referrals.component';
import { TranslateService } from '@ngx-translate/core';

import es from 'src/assets/i18n/es.json';
import { PermissionsHelper } from '../../../../helpers/Permission';
import Utils from '../../../../helpers/Utils';

@Component({
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
    @Input() sideNav: MatDrawer;
    @Output() toggle: EventEmitter<boolean> = new EventEmitter<boolean>()
    sidebarExpanded: boolean = false;

    public can_view_new_commissions: boolean;

    public user: User;

    public loader: boolean;

    public spinner: boolean;

    public availableUsersForLogin = [];

    public deviceStatus: any = localStore.get('device-status');

    public listener: any;

    public personalSettings = {};

    // public permissions: any

    public coin: any = {
        height: '100%',
    };

    public pauseStatuses: any;
    public deviceStatuses: any = [
        {
            label: 'header.Check_In',
            i18n: true,
            icon: 'fas fa-check-circle',
            name: 'checkin'
        },
        {
            label: 'header.Check_Out',
            i18n: true,
            icon: 'fas fa-sign-out-alt',
            name: 'checkout'
        },
    ];



    public onTop: any = '';


    public input: any = {
        firstname: 'Ricardo',
        lastname: 'O. Abubo Jr O. Abubo Jr',
        id: '1431931',
        info: [
            {
                label: 'Country of Birth:',
                value: 'Philippines'
            },
            {
                label: 'Email:',
                value: '150914103147@tets.comasdfadsasdasdadfsdfcomasdfadsasdasdadfsdf'
            },
            {
                label: 'Phone:',
                value: '+639189091768'
            },
            {
                label: 'Status Reason:',
                value: 'Unprocessed'
            },
            {
                label: 'Country of Residence:',
                value: 'Philippines'
            },
            {
                label: 'Campaign Source:',
                value: 'Old FB LP'
            },
            {
                label: 'Birthday:',
                value: '1985'
            }
        ]
    };

    public userMenu: any = [
        {
            hasChild: !this.isEmptyCallSystems,
            label: 'menu.Pause_status',
            i18n: true,
            icon: 'fas fa-pause',
        },
    ];

    public get showCheckInBtn(): boolean {
        return (this.deviceStatus !== 'agent:checkin') && !this.isEmptyCallSystems;
    }

    public get isEmptyCallSystems(): boolean {
        return Utils.isEmpty(localStore.get('call-systems'));
    }

    constructor(
        private matDialog: MatDialog,
        private store: Store<any>,
        private logOutUser: UserService,
        private checkService: CheckinService,
        private snackBar: MatSnackBar,
        private pauseService: PauseService,
        private ngZone: NgZone,
        private pushNotifications: PushNotificationsService,
        private userService: UserService,
        private snack: MatSnackBar,
        private quizService: QuizService,
        private centrifugeService: CentrifugeService,
        private translate: TranslateService,
        public permission: PermissionsHelper
    ) {
    }

    public getAllPermissions() {
        const user = localStore.get('user');

        this.CheckPossibilityToLoginAsOtherUser();
        this.CheckPossibilityToLoginBack();
        this.CheckPossibilityToLoginImmi(user);
    }

    public checkPossibilityToLoginAsOtherUser: boolean = false;
    public CheckPossibilityToLoginAsOtherUser(): void {
        const managerId = localStore.get('manager_id');
        if (managerId != null) {
            this.checkPossibilityToLoginAsOtherUser = false;
            return;
        }


      const settings = localStore.get('settings');
      if (get(settings, ['personal', 'available_users_for_login_as', 'value', 'value'], null) !== null) {
        const data = settings.personal.available_users_for_login_as.value.value;

        if (get(data, ['users'], null) !== null) {
          if (data.users.length > 0) {
            this.checkPossibilityToLoginAsOtherUser = true;
            return;
          }
        }
        if (get(data, ['roles'], null) !== null) {
          if (data.roles.length > 0) {
            this.checkPossibilityToLoginAsOtherUser = true;
            return;
          }
        }
        if (get(data, ['shifts'], null) !== null) {
          if (data.shifts.length > 0) {
            this.checkPossibilityToLoginAsOtherUser = true;
            return;
          }
        }
      }


      const data = this.permission.roleSettings('available_users_for_login');
      if (data) {
        if (get(data, ['users'], null) !== null) {
          if (data.users.length > 0) {
            this.checkPossibilityToLoginAsOtherUser = true;
            return;
          }
        }
        if (get(data, ['roles'], null) !== null) {
          if (data.roles.length > 0) {
            this.checkPossibilityToLoginAsOtherUser = true;
            return;
          }
        }
        if (get(data, ['shifts'], null) !== null) {
          if (data.shifts.length > 0) {
            this.checkPossibilityToLoginAsOtherUser = true;
            return;
          }
        }
      }
    }

    public checkPossibilityToLoginBack: boolean;
    public CheckPossibilityToLoginBack(): void {
        const managerId = localStore.get('manager_id');
        this.checkPossibilityToLoginBack = !!managerId;
    }

    public checkPossibilityToLoginImmi: boolean;

    public CheckPossibilityToLoginImmi(user): void {
        this.checkPossibilityToLoginImmi = user.role === 'mdc_dep';
    }


    ngOnInit() {

        this.getAllPermissions();

        window.addEventListener('localStore', this.listener = (event) => {
            if (event.key === 'device-status' && (event.newValue !== event.oldValue)) {
                this.deviceStatus = event.newValue;
            }
        });

        // window['pn'] = () => {
        //     this.store.dispatch(new PaymentNotificationActions.SetBubbleMessage(33333));
        // }

        this.subscribes.user = this.store.pipe(select('user')).subscribe((curUser) => {

            // if (this.user && this.user.level.id !== curUser.level.id) {
            //     this.congratsModal(curUser);
            // }

            this.user = curUser;
        });

        this.subscribes.incoming = this.logOutUser.incoming().subscribe((event) => {
            if (event === 'logout_all') {
                this.logOut();
            }
            // if (event.event === 'send_reward_notification') {
            //     this.ngZone.run(() => {
            //         if (document.visibilityState === 'hidden') {
            //             this.createPush();
            //         }
            //         this.matDialog.open(ViewRewardsModalComponent, {
            //             width: '800px',
            //             height: '500px',
            //             panelClass: 'reward-info',
            //             hasBackdrop: true,
            //             data: event.rewards
            //         });
            //     });
            // }
        });

        const lsCallSystems = localStore.get('call-systems');
        if(lsCallSystems) {
            this.pauseStatuses = Object.values(lsCallSystems).filter(
                (item: any) => Array.isArray(item.pause_statuses) && item.pause_statuses.length > 0
            );
        }

        this.device();

        const settingsSub = this.store.pipe(select((state) => {
            return localStore.get('settings');
        })).subscribe(u => {
            if (u && u['personal']) {
                this.personalSettings = u['personal'] || {};
            }
        });

        if (Object.keys(this.personalSettings).length >= 0) {
            settingsSub.unsubscribe();
        }

        this.initQuizzes();

    }


    public subscribes: any = {};
    ngOnDestroy() {
        for (const key in this.subscribes) {
            if (this.subscribes[key] && this.subscribes[key].unsubscribe) {
                this.subscribes[key].unsubscribe();
            }
        }
    }

    public BackToOriginAccount() {
        this.spinner = true;
        const managerId = localStore.get('manager_id');

        if (managerId !== null) {
            const id = Number(managerId);

            this.userService.getManagerLoginData(id, this.user.id).subscribe(res => {


                this.LoginBack(res.manager_username);

            }, error => {
                this.spinner = false;

                this.snack.open(error.error || 'Something went wrong...', 'Dismiss', {
                    duration: 3000,
                    panelClass: ['error-snackbar']
                });
            });
        }
    }

    public LoginBack(username) {

        this.userService.agent_login({
            body: Object.assign({
                username
            })
        }).toPromise().then((response) => {
            localStore.remove('manager_id');
            this.spinner = false;
            window.open('/login?agent_token=' + response.token, '_self');
        });
    }

    private device() {
        if (!this.subscribes.dSub) {
            this.subscribes.dSub = this.logOutUser.device().subscribe((res) => {
                this.deviceStatus = res.status;
            });
        }
    }

    public toggleSidebar() {
        this.sidebarExpanded = !this.sidebarExpanded;
        this.toggle.emit(this.sidebarExpanded);
    }

    public isAdmin() {
        return this.user.role === User.ADMIN;
    }

    public checkIn() {
        this.matDialog.open(CheckInManageComponent, {
            width: '500px',
        }).afterClosed().toPromise().then((i) => {
            this.checkService.getAvailableCallSystems().toPromise().then((res) => {
                const stringRes = JSON.stringify(res);

                if (stringRes.includes('agent:checkin')) {
                    this.deviceStatus = 'agent:checkin';
                    localStore.set('device-status', 'agent:checkin');
                } else {
                    this.deviceStatus = 'agent:checkout';
                    localStore.set('device-status', 'agent:checkout');
                }
            });
        });
    }

    public referrals() {
        this.ngZone.run(() => {
            this.matDialog.open(ReferralsComponent, {});
        })
    }

    public AgentsList() {
        this.matDialog.open(UsersLoginPopupComponent, {
            width: '750px',
            height: '540px',
            hasBackdrop: true,
        });
    }

    public LoginImmi() {
        window.open(environment.immiUrl + '/auto-login/' + encodeURIComponent(this.user.crm_api_key));
    }

    public checkOut() {
        this.matDialog.open(CheckInManageComponent, {
            width: '500px',
        }).afterClosed().toPromise().then((i) => {
            this.checkService.getAvailableCallSystems().toPromise().then((res) => {
                const stringRes = JSON.stringify(res);

                if (stringRes.includes('agent:checkin')) {
                    this.deviceStatus = 'agent:checkin';
                    localStore.set('device-status', 'agent:checkin');
                } else {
                    this.deviceStatus = 'agent:checkout';
                    localStore.set('device-status', 'agent:checkout');
                }
            });
        });
        // this.checkService.checkOut({}).subscribe((result) => {
        //     this.snackBar.open('Success!', 'Ok!', {
        //         duration: 5000,
        //     });
        //     localStore.set('device-status', 'agent:checkout');
        //     this.deviceStatus = 'agent:checkout';
        // }, (error) => {
        //     if (error.error) {
        //         for (const name in error.error) {
        //             if (error.error.hasOwnProperty(name)) {
        //                 this.snackBar.open(error.error[name], 'Ok!', {
        //                     duration: 6000,
        //                     panelClass: ['error-snackbar']
        //                 });
        //                 break;
        //             }
        //         }
        //     }
        //
        // });
    }

    private congratsModal(user) {
        this.matDialog.open(LevelModalComponent, {
            hasBackdrop: true,
            data: {
                user: user,
                text: 'LEVEL COMPLETED'
            }
        });
    }

    public changeStatus(callSystem, status) {
        const findKeyBySettingsName = (data, targetName) => {
            return Object.entries(data).find(
                ([key, value]) => {
                    return (value as any).settings.name === targetName
                }
            )[0];
        };

        const id = findKeyBySettingsName(localStore.get('call-systems'), callSystem.settings.name);

        let body = {
            system: parseInt(id, 10),
            status_id: status.id,
            status_name: status.name
        };

        this.pauseService.setPause(body).toPromise().then(() => {
            localStore.set('away', true);
            localStore.set('pause-title', { title: status.name });
            localStore.set('pause-id', id);
            document.dispatchEvent(new CustomEvent('awayChange'));
        }, (error) => {
            if (error.error) {
                for (const names in error.error) {
                    if (error.error.hasOwnProperty(names)) {
                        this.snackBar.open(error.error[names] || 'Something went wrong!', 'Ok!', {
                            duration: 10000000,
                            panelClass: ['error-snackbar']
                        });
                        break;
                    }
                }
            }
        });
    }

    // public callModal(status) {
    //     this.matDialog.open(AgentNotoficationModalComponent, {
    //         width: '500px',
    //         height: '370px',
    //         data: {
    //             status: status,
    //             title: '12312  fdadf asdfas df',
    //             desc: 'asdf asd fasdfasdfa asdfasdfasdfasd fs asdf asd fasdfasdfa asdfasdfasdfasd fs asdf asd fasdfasdfa asdfasdfasdfasd fs asdf asd fasdfasdfa asdfasdfasdfasd fs asdf asd fasdfasdfa asdfasdfasdfasd fs'
    //         },
    //         panelClass: 'notification-modal',

    //     });
    // }

    public changeDeviceStatus(name) {
        switch (name) {
            case 'checkin':
                this.checkIn();
                break;
            case 'checkout':
                this.checkOut();
                break;
        }
    }

    public logOut() {
        localStore.clearAll();
        this.logOutUser.logout().toPromise().then(() => {
            Cookies.set('token', '', -1);
            location.href = '/login';
        });
    }

    public createPush() {
        this.subscribes.new_reward = this.pushNotifications.create('NEW REWARD!!!', {
            body: 'New Reward is created!',
            dir: 'auto',
        }).subscribe((notif) => {
            if (notif.event.type === 'show') {
                setTimeout(() => {
                    notif.notification.close();
                }, 50000);
            }
        });
    }

    public canPersonalSettings(key: string): boolean {
        if (this.personalSettings && this.personalSettings[key]) {
            const setting = this.personalSettings[key] || {};

            switch (setting['type']) {
                case 'bool':
                    return Boolean(setting['value'] && setting['value']['value']);
                default:
                    console.warn('Not implemented functional for using personal settings "' + setting['type'] + '"');
                    break;
            }
        }

        return false;
    }



    private initQuizzes() {
        localStore.remove('quizId');
        const user = localStore.get('user');
        if (user && user.id) {
            this.checkQuizzes();
            this.initQuizzesCent();
        } else {
            localStore.remove(quizTypes.onLogin);
            localStore.remove(quizTypes.onHold);
            localStore.remove(quizTypes.onCent);
            localStore.remove('quizzesToDo');
            localStore.remove('quizSave');
        }
    }

    private checkHoldQuiz() {
        localStore.set(quizTypes.onHold);
        this.checkQuizzes();
    }

    private checkQuizWithCall(): void {
        if (this.quizAgentCallInterval) {
            clearInterval(this.quizAgentCallInterval);
        }
        this.quizAgentCallInterval = setInterval(() => {
            if (!localStore.get('agent_on_call') && !localStore.get('away')) {
                clearInterval(this.quizAgentCallInterval);
                setTimeout(() => {
                    this.checkQuizzes();
                }, 10000);
            }
        }, 5000);
    }
    private quizAgentCallInterval: any;
    private checkQuizzes(): void {
        const quizOnLogin = localStore.get(quizTypes.onLogin);
        const quizOnHold = localStore.get(quizTypes.onHold);
        const quizSave = localStore.get('quizSave');
        if (localStore.get('agent_on_call') || localStore.get('away')) {
            this.checkQuizWithCall()
        } else if (quizSave) {
            setTimeout(() => {
                this.openQuiz(quizSave.quiz, null, true);
            }, 1000);
        } else if (quizOnLogin) {
            this.quizService.onLogin().toPromise().then((quiz) => {
                if (!quiz) {
                    localStore.remove(quizTypes.onLogin);
                    return;
                }
                this.openQuiz(quiz, quizTypes.onLogin);
            }, () => { })
        } else if (quizOnHold) {
            this.quizService.onHold().toPromise().then((quiz) => {
                if (!quiz) {
                    localStore.remove(quizTypes.onHold);
                    return;
                }
                this.openQuiz(quiz, quizTypes.onHold);
            }, () => { })
        } else {
            // detectQuizzesToDo
            const quizzesToDo = localStore.get('quizzesToDo') || [];
            if (quizzesToDo.length) {
                setTimeout(() => {
                    this.openQuiz(quizzesToDo[0], quizTypes.onCent);
                }, 1000);
            }
        }
    }

    private initQuizzesCent(): void {
        const user = localStore.get('user');
        this.subscribes.quizC = this.centrifugeService.listen(
            `get_quiz_stream_id_${user.id}`
        ).subscribe((res) => {
            if (!(res && res.data && res.data.id)) { return; }
            const quiz = res.data;
            const currentQuizId = localStore.get('quizId');
            const quizzesToDo = localStore.get('quizzesToDo') || [];

            const busy = localStore.get('agent_on_call') || localStore.get('away');

            if (currentQuizId || busy) {
                if (currentQuizId !== quiz.id) {
                    if (busy) {
                        this.checkQuizWithCall();
                    }
                    const index = quizzesToDo.findIndex(el => el.id === quiz.id);
                    if (index !== -1) { return; }
                    quizzesToDo.push(quiz);
                    localStore.set('quizzesToDo', quizzesToDo);
                }
            } else {
                quizzesToDo.push(quiz);
                localStore.set('quizzesToDo', quizzesToDo);
                setTimeout(() => {
                    this.openQuiz(quiz, quizTypes.onCent);
                }, 500);
            }
        })
    }

    private openQuiz(quiz, quizOn, saved?): void {
        const id = quiz.id;
        localStore.set('quizId', id);
        this.ngZone.run(() => {
            this.matDialog.open(QuizPopupComponent, {
                disableClose: true,
                data: {
                    saved,
                    quiz,
                    quizOn,
                    callback: (status) => {
                        if (status === quizEndStatus.submitted) {
                            localStore.remove(quizOn);
                        } else if (status === quizEndStatus.closed) {
                            localStore.remove('quizId');
                            // this.checkQuizzes();
                        }
                    }
                }
            });
        });
    }
}
