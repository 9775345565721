import { Component, OnInit } from '@angular/core';
import { CheckinService } from '../../../services/checkin/checkin.service';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { CheckInModalComponent } from '../check-in-modal/check-in-modal.component';

const SIP = 'SIP';
const SIP_CMG = 'SIP-CMG';
const OpenTok = 'opentok';
const VOIP = 'VoipAppz';

@Component({
  selector: 'app-check-in-manage',
  templateUrl: './check-in-manage.component.html',
  styleUrls: ['./check-in-manage.component.scss']
})
export class CheckInManageComponent implements OnInit {

  public availableSystems = [];

  public loader = false;

  constructor(
    private checkInService: CheckinService,
    private snack: MatSnackBar,
    private matDialog: MatDialog,
    private self: MatDialogRef<CheckInManageComponent>,
  ) { }

  ngOnInit() {
    this.getAvailableCallSystems();
  }

  public getAvailableCallSystems() {
    this.loader = true;
    this.checkInService.getAvailableCallSystems().subscribe(res => {
      this.availableSystems = res;
      this.loader = false;
    }, err => {
      this.snack.open('The user can does not have access to any call system.', 'Ok!', {
        duration: 5000,
      });
      this.self.close();
    });
  }

  public checkOut(system: any) {
    switch (system.name) {
      case SIP:
      case SIP_CMG:
      case VOIP:
        this.checkoutSip(system.Id);
        break;
      case OpenTok:
        this.checkoutOpenTok();
        break;
    }
  }

  public checkIn(system) {
    switch (system.name) {
      case SIP:
      case SIP_CMG:
      case VOIP:
        this.checkinSip(system.Id);
        break
      case OpenTok:
        this.checkinOpenTok();
        break;
    }
  }

  public checkinSip(call_system_id) {
    this.matDialog.open(CheckInModalComponent, {
      width: '500px',
      data: {
        call_system_id
      }
    }).afterClosed().subscribe(res => {
      this.getAvailableCallSystems();
    });
  }

  public checkinOpenTok() {
    this.checkInService.checkInOpenTok().subscribe(res => {
      this.snack.open(res.success, 'Ok!', {
        duration: 5000,
      });
      this.getAvailableCallSystems();
    }, err => {
      this.snack.open(err.error, 'Ok!', {
        duration: 5000,
      });
    });
  }

  public checkoutSip(call_system_id) {
    this.checkInService.checkOutSip({call_system_id}).subscribe(res => {
      this.snack.open('Success', 'Ok!', {
        duration: 5000,
      });
      this.getAvailableCallSystems();
    }, err => {
      this.snack.open('Something Went Wrong!', 'Ok!', {
        duration: 5000,
      });
    });
  }

  public checkoutOpenTok() {
    this.checkInService.checkOutOpenTok().subscribe(res => {
      this.snack.open(res.success, 'Ok!', {
        duration: 5000,
      });
      this.getAvailableCallSystems()
    }, err => {
      this.snack.open(err.error, 'Ok!', {
        duration: 5000,
      });
    });
  }

}
