import _ceil from 'lodash/ceil';
import _get from 'lodash/get';
import _isFunction from 'lodash/isFunction';

export function newInstalmentDateHelper(index: number, beginAt: number = _ceil(Date.now() / 1000)): number {
    let periodJs = _get(
        this.instalmentData.instalments_periods.find(
            (item) => item.value === parseInt(this.instalmentGroup.get('period').value, 10)
        ), 'js', null);

    if (this.instalmentGroup.get('period').value === 5) {
        let period = this.instalmentGroup.get('custom_period').value;

        if (period <= 0) {
            period = 1;
        }

        periodJs = periodJs.replaceAll('{{custom_period}}', period);
    } else {
        // if (this.instalmentGroup.value.custom_period !== '1') {
        //   this.instalmentGroup.get('custom_period').setValue('1');
        // }
    }

    let currDate = new Date(beginAt * 1000);

    let CustomDate: { transform: (t: Date) => Date | null } = {
        transform: null,
    };

    try {
        // tslint:disable-next-line:no-eval
        CustomDate = eval(periodJs);
    } catch (e) {
        // console.log(e);
    }

    if (!CustomDate || !_isFunction(CustomDate.transform)) {
        CustomDate = {
            transform: (t: Date): Date => {
                t.setMonth(t.getMonth() + 1);

                return t;
            }
        };
    }

    while (index >= 0) {
        currDate = CustomDate.transform(currDate);

        index--;
    }

    return _ceil(currDate.getTime() / 1000);
}