import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs/Rx';

@Injectable({
    providedIn: 'root'
})
export class PauseService {

    constructor(private api: ApiService) {}

    public getCallSystems(params): Observable<any> {
        return this.api.get('/call-systems', {
            params
        })
    }

    public setPause(data): Observable<any> {
        return this.api.post('/pauses', {
            body: data
        });
    }
}
