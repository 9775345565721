import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnlockScreenComponent } from './unlock-screen.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule, MatSelectModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        UnlockScreenComponent
    ],
    imports: [
        CommonModule,
        MatSnackBarModule,
        MatDialogModule,
        MatSelectModule,
        MatButtonModule,
        ReactiveFormsModule,
        FormsModule
    ],
    exports: [
        UnlockScreenComponent
    ]
})
export class UnlockScreenModule {
}
