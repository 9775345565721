import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EventBusService } from 'src/app/services/event-bus/event-bus.service';
import { PointsService } from 'src/app/services/points/points.service';
import localStore from 'store';
import { HeaderPointsModalComponent } from '../header-points-modal/header-points-modal.component';

@Component({
  selector: 'app-header-points',
  templateUrl: './header-points.component.html',
  styleUrls: ['./header-points.component.scss',]
})
export class HeaderPointsComponent implements OnInit, OnDestroy {

  public user: any;
  public info_point: any = {
    active_points: 0,
    pending_points: 0
  };

  public progress: number;
  public progressForTooltip: string;

  public congratPopupSubs: any;
  public eventBusFromRewardsPage: any;

  public pending_points_animation: boolean;
  public active_points_animation: boolean;

  public show_pending_points: boolean;

  public pipInfo: any = {
    status : "",
    details: {
      title: "",
      points: {
        lock_point: ""
      }
    }
  };

  constructor(
    private dialog: MatDialog,
    private pointsService: PointsService,
    private eventBus: EventBusService
  ) { }

  ngOnInit(): void {
    this.user = localStore.get('user');
    this.pipInfo = this.user ? this.user.performance_process_details : this.pipInfo;

    this.pointsService.getHeaderPoints().toPromise().then((res) => {
      if (!(res && typeof res === 'object')) { return; }
      this.setData(res);
    }, (err) => {

    });
    this.eventBusFromRewardsPage = this.eventBus.on('update_points').subscribe((res: any) => {
      this.info_point = res;
      this.user.info_point.active_points = +res.active_points || 0;
      this.user.info_point.pending_points = +res.pending_points || 0;
      localStore.set('user', this.user)
    });
    // this.progress = 75;
  }

  public setData(result) {
    if (this.congratPopupSubs && this.congratPopupSubs.unsubscribe) {
      this.congratPopupSubs.unsubscribe();
    }
    this.info_point = result;

    this.show_pending_points = result.show_pending_points;
    localStore.set('show_pending_points', this.show_pending_points);

    this.user.info_point.active_points = result.active_points || 0;
    this.user.info_point.pending_points = result.pending_points || 0;
    localStore.set('user', this.user)

    this.progress = (this.info_point.active_points / this.info_point.achieved_points * 100);
    this.progressForTooltip = this.progress.toFixed(1);

    this.congratPopupSubs = this.eventBus.on('change-points-in-header').subscribe((
      { is_pending, achieved_points }
    ) => {
      // change-points-in-header
      if (is_pending) {
        this.info_point.pending_points += achieved_points;
        this.pending_points_animation = true;
        setTimeout(() => { this.pending_points_animation = false; }, 850);
      } else {
        if(this.info_point.pending_points > 0) {
          this.info_point.active_points = this.info_point.pending_points + achieved_points;
        } else {
          this.info_point.active_points = this.info_point.active_points + achieved_points;
        }
        this.info_point.pending_points = 0;
        
        this.active_points_animation = true;
        setTimeout(() => { this.active_points_animation = false; }, 850);
      }
      this.user.info_point.active_points = this.info_point.active_points;
      this.user.info_point.pending_points = this.info_point.pending_points;
      this.progress = (this.info_point.active_points / this.info_point.achieved_points * 100);
      this.progressForTooltip = this.progress.toFixed(1);
      localStore.set('user', this.user)
    })
    // change-points-in-header
  }

  ngOnDestroy() {
    if (this.congratPopupSubs && this.congratPopupSubs.unsubscribe) {
      this.congratPopupSubs.unsubscribe();
    }
    if (this.eventBusFromRewardsPage && this.eventBusFromRewardsPage.unsubscribe) {
      this.eventBusFromRewardsPage.unsubscribe();
    }
  }

  public openModal(): void {
    this.dialog.open(HeaderPointsModalComponent, {
      panelClass: 'points-popup',
    });
  }
}
