import store from 'store';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

type BoardFieldsTypes = 'assigned_only' | 'block_risk' | 'is_default' | 'view';

export class PermissionsHelper {

  private permissions = store.get('common_settings') || {};

  public can(group: any | string[], action: string | Array<string>, value: any = true): boolean {
    if (!this.permissions) {
      return false;
    }

    if (!Array.isArray(group)) {
      group = [group];
    }

    const checkPermission = (permissions: string): boolean => {
      const setting = _get(this.permissions, [...group, permissions, 'value'], null);

      return _isEqual(setting, value);
    };

    if (Array.isArray(action)) {
      return action.some(checkPermission);
    }

    return checkPermission(action);
  }

  public get(group: any | string[], action: string, value: 'value' | 'additional_data' = 'value'): boolean {
    if (!this.permissions) {
      return false;
    }

    if (!Array.isArray(group)) {
      group = [group];
    }

    return  _get(this.permissions, [...group, action, value], null);
  }

  public roleGeneral(action: string | Array<string>): boolean {
    const checkPermission = (permissions: string): boolean => _get(this.permissions, ['role', 'role_general_permissions', permissions], false);

    if (Array.isArray(action)) {
      return action.some(checkPermission);
    }

    return checkPermission(action);
  }

  public roleSettings(action: string, useValueKey = false): any {
    if (useValueKey) {
      return _get(this.permissions, ['role', 'role_settings', action, 'value'], null);
    }

    return _get(this.permissions, ['role', 'role_settings', action, 'additional_data'], null);
  }

  public boardSettings(entity: string, value: BoardFieldsTypes): any {
    return _get(this.permissions, ['role', 'role_board_permissions', entity, value], false);
  }

  /* 
    _get will handle 3 permission values:
      true:   can view and can edit
      false:  can only view
      null:   not exist in permissions at all, meaning cannot view and cannot edit
  */
  public fieldSettings(entity: string, field: string, value?: boolean | null): boolean | null {
    if (!value) {
      return _get(this.permissions, ['role', 'role_field_permissions', entity, field], null);
    }

    return _get(this.permissions, ['role', 'role_field_permissions', entity, field, value], null);
  }
}

