import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as UserActions from '../../actions/users.action';
import * as FieldsActions from '../../actions/fields.action';
import * as UActions from '../../actions/user.action';
import * as TimerActions from '../../actions/timer.action';
import { CurrentUser } from '../../helpers/CurrentUser';
import Maps from '../../helpers/Maps';
import storage from 'store';
import { FlipCardsService } from '../../services/flip-cards/flip-cards.service';
import * as ChartsActionUnion from '../../actions/charts.action';
import { UserService } from '../../services/users/user.service';
import { PushNotificationsService } from 'ng-push';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AgentNotoficationModalComponent } from '../../components/general/agent-notofication-modal/agent-notofication-modal.component';
import { CallOccupationModalComponent } from '../../components/modals/call-occupation-modal/call-occupation-modal.component';
import { SetPermissions } from '../../actions/permissions.action';
import { Cookies } from '../../helpers/Cookies';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { CentrifugeService } from '../../services/centrifuge/centrifuge.service';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import { map } from 'rxjs/operators/map';
import * as PaymentNotificationActions from '../../actions/payment_notification.action';
import { OpentokService } from '../../services/opentok/opentok.service';
import { NotificationPopupModalComponent } from '../../components/general/notification-popup-modal/notification-popup-modal.component';
import { EventBusService } from '../../services/event-bus/event-bus.service';
import { call_types } from 'src/app/components/general/call-activity-popup/constants/call_types';
import {SuccessPaymentModalComponent} from '../../components/general/success-payment-modal/success-payment-modal.component';
import localStore from 'store';
import {InstallmentsNotificationPopupComponent} from '../../components/info-right-menu/modals/payment-link-v2/installments-notification-popup/installments-notification-popup.component';
import {InstallmentNotificationService} from '../../services/installment/installmentNotification.service';
import {INSTALLMENT_NOTIFICATION_ROLES} from '../../constants/general';
import md5 from 'md5';
import {Title} from '@angular/platform-browser';
import {JWT} from '../../helpers/jwt';
import {DatePipe} from '@angular/common';
import {DEFAULT_EVENT_TIME} from '../../constants/notifications';
import {Observable} from 'rxjs';
import {LeadsService} from '../../services/leads/leads.service';
import {ApplicationsService} from '../../services/applications/applications.service';
import {encodeObj} from '../../helpers/encodeObj';
import {CreateLeadModalComponent} from '../../components/general/create-lead-modal/create-lead-modal.component';
import { ConfirmModalComponent } from 'src/app/components/general/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnInit, OnDestroy {

  public listener: any;

  public handleEvent: any;

  public subscribes: any = {};

  public away: any;

  public lock: any = storage.get('away');

  public currentUser: any;

  public settings: any;

  public modal: any = null;

  public callWindowInterval: any;

  public isCallEnded = false;
  public acceptCall = false;

  public audioSound = null;

  public personalSettings = {};
  public permissions: any;

  public hasActiveModal: boolean = false;

  private pendingMessages = [];

  public activeCallLeadNumber = null;
  public activeCallPrint = null;
  public activeCallParams = null;

  constructor(
    private titleService: Title,
    private ngZone: NgZone,
    private router: Router,
    private store: Store<any>,
    private filpCards: FlipCardsService,
    private userService: UserService,
    private user: UserService,
    private _pushNotifications: PushNotificationsService,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private centrifugeService: CentrifugeService,
    private opentokService: OpentokService,
    private eventBus: EventBusService,
    private datePipe: DatePipe,
    private installmentNotificationService: InstallmentNotificationService,
    private leadsService: LeadsService,
    private applicationsService: ApplicationsService
  ) {

    const users = storage.get('users');

    this.currentUser = storage.get('user');

    const autocomplete = storage.get('autocomplete');

    this.settings = storage.get('settings');

    if (users) {
      this.store.dispatch(new UserActions.SetUsers(users));
      this.store.dispatch(new UserActions.SetUsersMap(Maps.create(users, 'crm_id')));
    }

    if (autocomplete) {
      this.store.dispatch(new FieldsActions.SetFields(autocomplete));
    }

    this.store.dispatch(new UActions.SetUser(CurrentUser.getUser()));

    this.subscribes.fcIncom = this.filpCards.incoming().subscribe((event) => {
      this.store.dispatch(new ChartsActionUnion.SetCharts(event));
    });

    this.subscribes.usrInc = this.user.incoming().subscribe((event) => {
      if (event.event && event.event === 'is_blocked') {
        storage.set('blocked', event.lead);
        this.showOccupationModal(event.lead, this.currentUser.id);
      }
    });

    this.subscribes.uSubs = this.user.incoming().subscribe((event) => {
      if (!event.event || event.event !== 'is_blocked') {
        if (event.event !== 'send_reward_notification') {
          CurrentUser.setUser(event);
          this.store.dispatch(new UActions.SetUser(event));
        }
      }
    });

    const permissions = storage.get('permissions');

    this.store.dispatch(new SetPermissions(permissions));

    this.timer();

    this.createPush();

    this.subscribes.centCon = this.centrifugeService.connect().subscribe(() => {
      this.agentsOnline();
      this.ivrForConversion();
      this.paymentNotification();
      this.popupNotification();
      this.installment();
      this.successPaymentNotification();
      this.subscribeScheduleNotification();
      this.OpentokCallRequests();
    });

    this.gtag('event', 'agent_name', {
      'event_category': 'agent_name',
      'event_label': 'Agent Page Visit',
      'value': this.currentUser.full_name
    })
  }

  ngOnInit() {
    this.checkTabs();

    this.subscribes.nSub = this.user.kpi().subscribe((nn) => {

      if (nn.type === 'default') {
        if (this.modal) {
          this.modal.close(true);
        }
        this.ngZone.run(() => {
          this.modal = this.matDialog.open(AgentNotoficationModalComponent, {
            width: '500px',
            height: '370px',
            data: Object.assign({}, nn),
            panelClass: 'notification-modal',
          });
        });

      }

      if (nn.type === 'block') {
        storage.set('away', nn.status === 'block');
        storage.set('pause-icon', { icon: 'fas fa-lock' });
        storage.set('pause-title', { title: nn.message });
        document.dispatchEvent(new CustomEvent('awayChange'));
      }

    });

    this._pushNotifications.requestPermission();

    window.addEventListener('storage', this.listener = (event) => {
      if (event.key === 'away' && (event.newValue !== event.oldValue)) {
        this.lock = storage.get('away');
        this.timer();
      }
    });
    document.addEventListener('awayChange', this.away = () => {
      this.lock = storage.get('away');
      this.timer();
    });

    this.subscribes.callSub = this.user.call().subscribe((ev) => {
      if (ev.event_name && ev.event_name === 'end') {
        // @ts-ignore
        if (window.callModal && ev.call_id === window.callModalUid) {
          // @ts-ignore
          window.callModal.postMessage({ message: 'end_call' }, '*');
        }
      } else {
        this.createCallRequestWindow(ev);
      }
    });

    const settingsSub = this.store.pipe(select((state) => {
      return storage.get('settings');
    })).subscribe(u => {
      if (u && u['personal']) {
        this.personalSettings = u['personal'] || {};
      }
    });

    if (Object.keys(this.personalSettings).length >= 0) {
      settingsSub.unsubscribe();
    }

    this.audioSound = new Audio('https://timcarli-ecommerce.s3-us-west-2.amazonaws.com/sounds/video_call.mp3');
    this.audioSound.loop = true;
    this.audioSound.volume = 0.4;

    this.isBlocked();

  }

  private gtag(...args) {
    try {
      (window as any).dataLayer.push(arguments);
    }
    catch (e) { }
  }

  private isBlocked() {
    const blockedUser = storage.get('blocked');
    const user = storage.get('user');
    if (blockedUser) {
      this.showOccupationModal(blockedUser, user.id);
    }
  }

  public showOccupationModal(lead: object, userId: number) {
    this.ngZone.run(() => {
      const dialog = this.matDialog.open(CallOccupationModalComponent, {
        width: '400px',
        disableClose: true,
        data: {
          lead,
          userId
        }
      });
      dialog.afterClosed().toPromise().then(() => {
        storage.remove('blocked');
      });
    });
  }

  private createPush() {
    this.subscribes.cSub = this.user.notifications().subscribe((event) => {
      if (!event) { return; }
      const date = new Date();

      this.snackBar.open(event.title + ' in ' + this.getMinutes(event.related_at, date.getTime()) + ' minutes', 'Dismiss!', {
        duration: 5000,
      }).afterDismissed().toPromise().then((result) => {
        if (result.dismissedByAction && event.related_id) {
          if (event.related_id) {
            window.open('/details/' + event.related_type + '/' + event.related_id);
          }
        }
      });
      this.subscribes[`tta${date.getTime()}`] = this._pushNotifications.create(event.title, {
        body: event.text + '\n\n' + 'Time to action: ' + this.getMinutes(event.related_at, date.getTime()) + ' minutes',
        icon: this.getAgentAvatar(this.currentUser.crm_id),
        dir: 'auto',
      }).subscribe((notif) => {
        if (notif.event.type === 'show') {
          setTimeout(() => {
            notif.notification.close();
          }, 50000);
        }
        if (notif.event.type === 'click') {
          if (event.related_id) {
            window.open('/details/' + event.related_type + '/' + event.related_id);
          }
          notif.notification.close();
        }
      });
    });
  }

  private timer() {
    if (!this.subscribes.tSub && !this.lock) {
      this.subscribes.tSub = this.user.timer().subscribe((event) => {
        this.store.dispatch(new TimerActions.Set(parseInt(event.time, 10)));
      });
    }
    if (this.lock && this.subscribes.tSub) {
      this.subscribes.tSub.unsubscribe();
      this.subscribes.tSub = null;
    }
  }

  private getMinutes(time1, time2) {
    return Math.floor((time1 - Math.floor(time2 / 1000)) / 60);
  }

  private ivrForConversion() {
    const channelName = `sip_dialer-${this.currentUser.crm_id}`;

    this.subscribes.ivrSubs = this.centrifugeService.listen(channelName).pipe(
      map(res => _get(res, 'data', {})),
    ).subscribe((data) => {
      const callStatus = _get(data, 'call_status', 'call:ring');

      switch (callStatus) {
        case 'call:answer':
          const agentOnCall = localStore.get('agent_on_call');
          if (Boolean(agentOnCall)) {
            const call_data = storage.get('call_data') || {};

            this.endCall(call_data);
          }

          const newData = {
            lead_phone: data.phone,
            lead_crm_id: data.entity_crm_id,
            lead_id: data.entity_id,
            entity_type: data.entity_type,
            lead_number: data.lead_number,
            first_name: data.first_name,
            last_name: data.last_name,
            call_id: data.call_id,
            type: data.type
          };

          this.showCAllNotification(newData);

          break;

        case 'call:end':
          this.endCall(data);

          this.eventBus.emit('push-notification', {
            action: 'delete',
            notification: {
              type: 'call',
              id: data.call_id,
            }
          });
          break;
      }
    });
  }

  private OpentokActiveSession(number: string) {
    const hash =  md5(number)

    const channelName = `active_session_2_${hash}`;

    this.subscribes.caSubs = this.centrifugeService.listen(channelName)
    .pipe(
      map(res => _get(res, 'data', {})),
      // filter(data => !!_get(data, 'call_id')),
    )
      .subscribe((ev) => {
        if (ev.status === false) {
          // @ts-ignore
          window.callModal.postMessage({ message: 'close_call' }, '*');

          this.centrifugeService.dissconnectChannel(channelName);
          this.centrifugeService.dissconnectChannel(`call_requests_${this.currentUser.id}`);

          this.ringtone(false);
        }
      });
  }

  public callCount = 0;
  private showCAllNotification(data): void {
    storage.set('agent_on_call', true);
    storage.set('call_data', data);
    storage.set('call_answer', new Date().getTime());

    this.callCount += 1;

    const eventTime = new Date().getTime();

    let notification = {
      action: 'create',
      notification: {
        type: 'call',
        icon: '../../../assets/icons/call_icon.png',
        title: data.lead_number ? `${data.first_name} ${data.last_name} - ${data.lead_number}` : 'From Unknown client',
        description: data.lead_phone || null,
        text: `Type: ${data.type === call_types.inbound ? 'Inbound' : 'Outbound'}`,
        id: data.call_id,
        onOpen: () => {
          this.redirectToEntity(data);
        },
        onClose: () => {
          if (this.callCount > 1) {
            this.endCall(data);
          }

          this.callCount -= 1;

          setTimeout(() => {
            const call_data = localStore.get('call_data');
            if (call_data) {
              const now = new Date().getTime();
              this.ngZone.run(() => {
                this.eventBus.emit('call-timer', {
                  status: true,
                  dueDate: now - eventTime,
                  body: data
                });
              });
            }
          }, 1000);
        },
        settings: {
          btnName: 'Profile',
          timer: true,
          closeOnOpen: true
        }
      }
    };

    if (!data.lead_number) {
      this.eventBus.emit('call-unknown',  {
        status: true,
        body: data
      });

      notification = {
        action: 'create',
        notification: {
          ...notification.notification,
          onOpen: () => {
            this.matDialog.open(
              CreateLeadModalComponent,
            {
              width: '550px',
              panelClass: 'create-template',
              data: {
                type: 'inbound-call',
                formData: data
              }
            }).afterClosed().subscribe(res => {
              if (res) {
                this.eventBus.emit('call-unknown',  {
                  status: false
                });

                this.updateNotification(res, data);
              }
            });
          },
          settings: {
            btnName: 'Create',
            timer: true,
            closeOnOpen: false
          }
        }
      };
    }

    this.eventBus.emit('push-notification', notification);
  }

  private OpentokCallRequests() {
    const channelName = `call_requests_${this.currentUser.id}`;

    this.subscribes.caSubs = this.centrifugeService.listen(channelName)
      .pipe(
        map(res => _get(res, 'data', {})),
      )
      .subscribe((ev) => {
        setTimeout(() => {
          if (ev.status === false) {
            if ((ev.md5_lead_number === this.activeCallLeadNumber) || (ev.user_print === this.activeCallPrint)) {
              // @ts-ignore
              window.callModal.postMessage({ message: 'close_call' }, '*');

              this.ringtone(false);
            }
          } else {
            const hasCall = localStorage.getItem(`call_${ev.session_id}`);
            if (!hasCall) {
              localStorage.setItem(`call_${ev.session_id}`, 'true');

              this.createCallRequestWindow(ev);
            }
          }
        }, Math.floor(Math.random() * 1001));
      });
  }

  private updateNotification(data, initial): void {
    const notification = {
      action: 'update',
      notification: {
        title: `${data.first_name} ${data.last_name} - ${data.lead_number}`,
        description: data.lead_phone || null,
        text: `Type: ${initial.type === call_types.inbound ? 'Inbound' : 'Outbound'}`,
        id: initial.call_id,
        onOpen: (res) => {
          this.redirectToEntity(res);
        },
        settings: {
          btnName: 'Profile',
          timer: true
        }
      }
    };

    this.eventBus.emit('push-notification', notification);
  }

  public redirectToEntity(data) {
    if (data.lead_crm_id) {
      let getEntiry = null;
      if (data.entity_type === 'leads') {
        getEntiry = this.getLead(data);
      } else if (data.entity_type === 'applications') {
        getEntiry = this.getApplication(data);
      }

      if (getEntiry) {
        getEntiry.toPromise().then((res) => {
          const leadId = _get(res, ['data', '0', 'id'], null);
          if (leadId) {
            this.router.navigate([`/details/${data.entity_type}/${leadId}`]);
          }
        });
      }
    }
  }

  public getLead(data): Observable<any> {
    return this.leadsService.getAllLeads({filter: encodeObj({crm_id: data.lead_crm_id})});
  }

  public getApplication(data): Observable<any> {
    const id = data.application_crm_id ? data.application_crm_id : data.lead_crm_id;

    return this.applicationsService.getAllList({filter: encodeObj({crm_id: id})});
  }

  private endCall(data: any = {}): void {
    this.eventBus.emit('call-timer', {
      status: false,
    });

    storage.remove('agent_on_call');
    storage.remove('call_data');
    storage.remove('call_answer');
  }

  private paymentNotification() {
    const channelName = `agent_${this.currentUser.id}_payment_notification`;

    this.subscribes.pnSubs = this.centrifugeService.listen(channelName).pipe(
      map(res => _get(res, 'data', {})),
    ).subscribe((data) => {
      let unread = storage.get('unread_payment_notifications');
      if (unread === undefined) {
        unread = 0;
      }
      unread = unread + 1;

      this.store.dispatch(new PaymentNotificationActions.SetNotViewedNotifications({ count: unread, data: data }));
      this.store.dispatch(new PaymentNotificationActions.SetBubbleMessage(data));
    });
  }

  private successPaymentNotification(): void {
    const channelName = `success_payment_${this.currentUser.crm_id}`;

    this.centrifugeService.listen(channelName).pipe(
      map(res => _get(res, 'data', [])),
    ).subscribe((data) => {
      this.ngZone.run(() => {
        if (Array.isArray(data)) {
          data.forEach((event) => {
            if (event.notification === 'evaluation') {
              if (this.hasActiveModal) return;

              this.hasActiveModal = true;

              this.ngZone.run(() => {
                this.matDialog.open(SuccessPaymentModalComponent, {
                  autoFocus: false,
                  width: '500px',
                  data: event.data
                }).afterClosed().toPromise().then(() => {
                  this.hasActiveModal = false;
                });
              });
            } else if (event.notification === 'sale') {
              const count = storage.get('unread_payment_notifications') || 0;

              this.store.dispatch(new PaymentNotificationActions.SetNotViewedNotifications({ count: count + 1 }));

              if (event.type === 'agent') {
                this.store.dispatch(new PaymentNotificationActions.TogglePaymentNotifications(true));
              }
            }
          });
        }
      });
    });
  }

  private subscribeScheduleNotification(): void {
    const channelName = `agent_${this.currentUser.id}_schedule_notification`;

    this.centrifugeService.listen(channelName).pipe(
      map(res => _get(res, 'data', {})),
    ).subscribe((data) => {
      if (data && data.notification_id && data.notification_type && data.notification_title) {

        const notification = {
          action: 'create',
          notification: {
            type: 'notification',
            icon: this.getAgentAvatar(data.notification_id),
            title: `${data.notification_type}: ${data.notification_title}`,
            description: `Description: ${data.notification_description}`,
            date: `${this.datePipe.transform(data.notification_time * 1000, 'EEEE, MMMM d ⋅ H:mm')} - ${this.datePipe.transform(data.notification_time * 1000 + DEFAULT_EVENT_TIME, 'H:mm' )}`,
            text: `In ${data.count} ${data.type}`,
            id: data.notification_id,
            settings: {
              closeOnOpen: true
            },
            additional_info: {
              type: data.notification_type
            },
            onOpen: () => {
              this.router.navigate([`/details/${data.notification_entity}/${data.related_id}`]);
            }
          }
        };

        this.eventBus.emit('push-notification', notification);
      }
    });
  }

  private installment() {
    if (INSTALLMENT_NOTIFICATION_ROLES.includes(this.currentUser.role)) {
      const channelName = `agent_${this.currentUser.id}_installment`;

      this.subscribes.insSubs = this.centrifugeService.listen(channelName).pipe(
        map(res => _get(res, 'data', {})),
      ).subscribe((data) => {
        this.installmentNotificationService.increase();

        this.showInstallmentModal(data);
      });
    }
  }

  private showInstallmentModal(data) {
    if (this.hasActiveModal) return;

    this.hasActiveModal = true;

    this.ngZone.run(() => {
      this.matDialog.open(InstallmentsNotificationPopupComponent, {
        minWidth: '500px',
        minHeight: '500px',
        data: {
          lead_number: data.lead_number,
          product: data.product,
          payment_amount: Number(data.paid_amount)
        }
      }).afterClosed().toPromise().then((result) => {
        this.hasActiveModal = false;

        if (typeof result === 'string') {
          this.snackBar.open(result, 'Ok!', {
            duration: 3000,
          });
        }
      });
    });
  }

  private popupNotification() {
    const channelName = `agent_${this.currentUser.id}_popup_notification`;

    this.subscribes.pnSubs = this.centrifugeService.listen(channelName).pipe(
      map(res => _get(res, 'data', {})),
    ).subscribe((data) => {
      if (data && data.messages && typeof data === 'object' && Array.isArray(data.messages)) {
        this.showNotification(data.messages);
      } else if (typeof data === 'object' && !Array.isArray(data)) {
        this.showNotification([data]);
      } else {
        this.showNotification([JSON.parse(data)]);
      }
    });
  }


  private showNotification(messages: any[]) {

    if (this.hasActiveModal) {
      this.pendingMessages.push(...messages);
      return;
    }

    this.hasActiveModal = true;

    this.ngZone.run(() => {
      this.matDialog.open(NotificationPopupModalComponent, {
        width: '600px',
        hasBackdrop: true,
        data: messages
      }).afterClosed().toPromise().then(() => {
        setTimeout(() => {
          this.hasActiveModal = false;
          if (this.pendingMessages && this.pendingMessages.length > 0) {
            const msgs = this.pendingMessages;
            this.pendingMessages = [];
            this.showNotification(msgs);
          }
        }, 3000);
      });
    });
  }

  private agentsOnline() {
    this.subscribes.aoSubs = this.centrifugeService.listen(`agents`).subscribe((res) => {
    });
  }

  ngOnDestroy() {
    if (this.listener) {
      window.removeEventListener('storage', this.listener);
    }
    if (this.away) {
      document.removeEventListener('awayChange', this.away);
    }
    if (this.handleEvent) {
      window.removeEventListener('message', this.handleEvent);
    }

    for (const key of this.subscribes) {
      if (this.subscribes[key] && this.subscribes[key].unsubscribe) {
        this.subscribes[key].unsubscribe();
      }
    }

    clearInterval(this.callWindowInterval);
  }

  public ringtone(__bool) {
    try {
      if (!this.audioSound) {
        return;
      }

      if (__bool) {
        const playPromise = this.audioSound.play();
        if (typeof playPromise !== 'undefined') {
          playPromise.then(() => {
            this.audioSound.play();
          }).catch(error => {
            // console.log('error', error);
          });
        }
      } else {
        if (this.audioSound) {
          this.audioSound.pause();
          this.audioSound.currentTime = 0;
        }
      }
    } catch (e) {}
  }


  private isEndCall = false;
  private endCallOpentok(params, session, disconnect_message?, toDecline = false): void {
    if (this.isEndCall) {
      return
    }
    this.isEndCall = true;

    const body: any = {
      api_key: params.api_key,
      call_system: params.call_system,
      session_id: params.session_id,
      token: params.token,
      assign_field: params.assign_field,
      entity: params.entity,
      entity_id: params.entity_id,
      md5_lead_number: params.md5_lead_number,
    };

    if(disconnect_message) {
      body.disconnect_message = disconnect_message;
    }

    if(params.not_lead === true){
      body.user_print = params.user_print;
    }

    if (session && !toDecline) {
      this.opentokService.end(body).toPromise();
    } else {
      this.opentokService.decline(body).toPromise();
    }

    window.removeEventListener('message', this.handleEvent);
  }

  private callPollTimer = null;
  public createCallRequestWindow(params) {
    let notify = null;
    this.isEndCall = false;
    const windowFeatures = 'location=yes, resizable=yes, scrollbars=yes, status=yes, width=500, height=600';
    const auth_token = Cookies.get('token');

    const jwt = JWT.encode({
      ...params,
      url: window.location.origin,
      auth_token: auth_token,
      secretKey: environment.secretKey,
      apiURL: environment.apiURL
    });

    const url = `${environment.callUrl}/index.html?token=${jwt}&ctmp=${Date.now()}`;

    try {
      this.ringtone(true);
      // @ts-ignore
      window.callModal = window.open(url, '_blank', windowFeatures);

      if(!(window as any).callModal) {
        this.endCallOpentok(params, true, 'Modals blocked', true);
        this.ringtone(false);
        return;
      }

      // @ts-ignore
      window.callModalUid = params.session_id;

      // @ts-ignore
      // window.callModal.onload = () => {
      //   // @ts-ignore
      //   window.callModal.onunload = () => {
      //     console.log('---onunload--');
      //     this.endCallOpentok(params, this.acceptCall);
      //     this.ringtone(false);
      //   }
      // }

      this.callPollTimer = setInterval(() => {
        // @ts-ignore
        if (window.callModal && window.callModal.closed) {
          if (!this.isCallEnded) {
            clearInterval(this.callPollTimer);

            this.endCallOpentok(params, this.acceptCall);
            this.ringtone(false);
          }
          // Additional cleanup code here
        }
      }, 1000);

      storage.set('active_call', { id: params.session_id, call_id: params.session_id });

      this.isCallEnded = false;
      this.acceptCall = false;

      this.activeCallLeadNumber = params.md5_lead_number;
      this.activeCallPrint = params.user_print;
      this.activeCallParams = params;

      this.titleService.setTitle(`Client is calling | CvAgentDash`);

      window.addEventListener('message', this.handleEvent = (ev) => {

        if (ev.data.message === 'accept_call') {
          if(params.not_lead == true){
            this.acceptCall = true;
            this.listenSuccessLead();
          }

          if (ev.data.lead_id) {
            this.acceptCall = true;

            this.OpentokActiveSession(ev.data.lead_number);

            if (params.entity && params.entity_id) {
              setTimeout(() => {
                this.ngZone.run(() => {
                  this.router.navigate([`/details/${params.entity}/${params.entity_id}`]);
                });
              }, 3000)
            }
          }
        }

        if (ev.data.message === 'click_somewhere') {
          this.ringtone(false);
        }

        if(ev.data.message === 'log') {
          console.log(ev.data.info);
        }

        if (ev.data.message === 'call') {
          if (ev.data.action === 'view_info' && ev.data.url) {
            this.titleService.setTitle(`${params.lead_number} - Client Details | Agent`);

            window.focus();

            this.ngZone.run(() => {
              this.router.navigate([ev.data.url]);
            });
          }
        }

        if (ev.data.message === 'end_call' || ev.data.message === 'end_call_api') {
          if(ev.data.message === 'end_call_api'){
            this.endCallOpentok(params, this.acceptCall, ev.data.disconnect_message)
          }
          this.titleService.setTitle(`CvAgentDash`);
          this.isCallEnded = true;
          storage.remove('active_call');
          window.removeEventListener('message', this.handleEvent);
          this.ringtone(false);
          localStorage.removeItem(`call_${this.activeCallParams.session_id}`);
        }

        if(ev.data.message === 'call_register_lead'){
          window.focus();

          this.openRegisterLeadModal(ev.data.user_print);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  public openRegisterLeadModal(user_print: any){
    this.ngZone.run(() => {
      this.matDialog.open(CreateLeadModalComponent, {
        width: '550px',
        panelClass: 'create-template',
        data: {
          user_print: user_print,
          not_lead: true
        }
      });
    })
  }

  private listenSuccessLead(): void{
    this.subscribes.srUs = this.centrifugeService.listen('success_register_user_print_' + this.activeCallPrint)
      .pipe(
        map(res => _get(res, 'data', {})),
      )
      .subscribe((ev) => {
        if (ev.status === true && ev.lead_id) {

          if(window.location.pathname != `/details/leads/${ev.lead_id}`){
            this.ngZone.run(() => {
              window.focus();

              const dialog = this.matDialog.open(ConfirmModalComponent, {
                width: '500px',
                hasBackdrop: true,
                data: {
                  title: 'Client registered!',
                  message: 'Your client have successfuly registered! Do you want to visit his personal page?'
                }
              });
              dialog.afterClosed().subscribe((result) => {
                if(result){
                  this.ngZone.run(() => {
                    this.router.navigate([`/details/leads/${ev.lead_id}`]);
                  });
                }
              })
            });
          }

          if ((window as any).callModal) {
            (window as any).callModal.postMessage({ message: 'call_register_lead_success' }, '*');
          }
        }
      });
  }

  public checkTabs() {
    if (this.settings.personal.agent_limit_tabs &&
      (this.settings.personal.agent_limit_tabs.value === null || this.settings.personal.agent_limit_tabs.value.value === 0)) {
      return;
    }

    this.centrifugeService.getActiveTabs().then((mes) => {
      const tabs = _filter(mes.presence, (el) => {
        const connInfo = JSON.parse(new TextDecoder().decode(el.conn_info));
        return el.user === String(this.currentUser.id) && (!connInfo.from_admin || connInfo.from_admin === false)
      });

      if (tabs.length > this.settings.personal.agent_limit_tabs.value.value) {
        window.open('about:blank', '_self').close();
      }
    });
  }

  public getAgentAvatar(crm_id): string {
    return this.userService.getAvatar(crm_id);
  }

  public canPersonalSettings(key: string): boolean {
    if (this.personalSettings && this.personalSettings[key]) {
      const setting = this.personalSettings[key] || {};

      switch (setting['type']) {
        case 'bool':
          return Boolean(setting['value'] && setting['value']['value']);
        default:
          // console.warn('Not implemented functional for using personal settings "' + setting['type'] + '"');
          break;
      }
    }

    return false;
  }
}
