import { Component, OnInit, ViewChild } from '@angular/core';
import store from 'store';
import { PauseService } from '../../../services/pause/pause.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Observable, Subscription } from 'rxjs/Rx';
import { QuizPopupComponent } from '../../quiz-popup/quiz-popup.component';
import { QuizService } from 'src/app/services/quiz/quiz.service';
import { User } from 'src/app/services/users/user';



@Component({
    selector: 'app-unlock-screen',
    templateUrl: './unlock-screen.component.html',
    styleUrls: ['./unlock-screen.component.scss']
})
export class UnlockScreenComponent implements OnInit {

    public ticks = 0;

    public minutesDisplay: number = 0;
    public hoursDisplay: number = 0;
    public secondsDisplay: number = 0;

    public sub: Subscription;

    private user: User;

    @ViewChild('input') public input: any;

    public disabled: any = false;

    public pauseReason: any = {
        title: store.get('pause-title')
    };

    public showSelect = false;
    public selectedStatus = null;
    public options = [];

    constructor(
        private pauseService: PauseService,
        private quizService: QuizService,
        private snackBar: MatSnackBar,
        private matDialog: MatDialog,
    ) {
    }

    ngOnInit() {
        this.startTimer();
        this.user = store.get('user');
    }

    public getValue() {
        if (this.input.nativeElement.value < 100) {
            this.input.nativeElement.value = 0;
        } else {
            const defaultStatus = this.getDefaultActiveStatus();

            if (defaultStatus) {
                this.updateStatus(defaultStatus);
            } else {
                this.showSelect = true;
                this.options = store.get('call-systems')[store.get('pause-id')].pause_statuses;
            }
        }
    }

    public onClickSetStatusBtn(): void {
        const data = {
            status_id: this.selectedStatus.id,
            status_name: this.selectedStatus.name,
            system: parseInt(store.get('pause-id'), 10)
        };

        this.updateStatus(data);
    }

    private updateStatus(data) {
        this.disabled = true;
        store.set('away', false);
        document.dispatchEvent(new CustomEvent('awayChange'));
        
        this.pauseService.setPause(data).toPromise().then(() => {
            this.checkQuiz();
        }, (error) => {
            if (error.error) {
                for (const name in error.error) {
                    if (error.error.hasOwnProperty(name)) {
                        this.snackBar.open(error.error[name], 'Ok!', {
                            duration: 6000,
                            panelClass: ['error-snackbar']
                        });
                        break;
                    }
                }
            }
        });
    }

    private getDefaultActiveStatus() {
        const id = store.get('pause-id');
        const callSystems = store.get('call-systems');

        if (
            callSystems[id] &&
            callSystems[id].settings &&
            callSystems[id].settings.default_active_status
        ) {
            return {
                status_id: callSystems[id].settings.default_active_status.id,
                status_name: callSystems[id].settings.default_active_status.name,
                system: parseInt(id, 10)
            }
        } else {
            return null;
        }
    }

    private checkQuiz() {
        this.quizService.onHold().toPromise().then((quiz) => {
            if (!quiz) { return; }
            store.set('quizOnHold', true);
            this.matDialog.open(QuizPopupComponent, {
                disableClose: true,
                data: { quiz, quizOn: 'quizOnHold' }
            })
        }, () => { })
    }

    private startTimer() {
        const timer = Observable.timer(1, 1000);

        this.sub = timer.subscribe(
            t => {
                this.ticks = t;

                this.secondsDisplay = this.getSeconds(this.ticks);
                this.minutesDisplay = this.getMinutes(this.ticks);
                this.hoursDisplay = this.getHours(this.ticks);
            }
        );
    }

    private getSeconds(ticks: number) {
        return this.pad(ticks % 60);
    }

    private getMinutes(ticks: number) {
        return this.pad((Math.floor(ticks / 60)) % 60);
    }

    private getHours(ticks: number) {
        return this.pad(Math.floor((ticks / 60) / 60));
    }

    private pad(digit: any) {
        return digit <= 9 ? '0' + digit : digit;
    }
}
