import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { InstalmentForm as DetectInstalmentForm, InstalmentPiece } from '../../../../general/instalments/instalments.datatypes';
import { select, Store } from '@ngrx/store';
import localStore from 'store';
import { pairwise, startWith } from 'rxjs/operators';
import { DateConverter } from '../../../../../helpers/DateConverter';
import { DiscountModalComponent } from '../../discount-modal/discount-modal.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { PaymentService } from '../../../../../services/payment/payment.service';
import { UserInfoService } from '../../../../../services/user-info/user-info.service';
import { QuestionerService } from '../../../../../services/questioner/questioner.service';
import { DiplomasService } from '../../../../../services/diplomas/diplomas';
import { DetectTypeFilter } from '../../../info-right-menu.dataset';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _sumBy from 'lodash/sumBy';
import _min from 'lodash/min';
import _max from 'lodash/max';
import _range from 'lodash/range';
import _toNumber from 'lodash/toNumber';
import _ceil from 'lodash/ceil';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import _isFunction from 'lodash/isFunction';
import _round from 'lodash/round';
import _floor from 'lodash/floor';
import _toInteger from 'lodash/toInteger';
import { InstallmentService } from '../../../../../services/installment/installment.service';
import { ConfirmModalComponent } from '../../../../general/confirm-modal/confirm-modal.component';
import { InstallmentNotificationService } from '../../../../../services/installment/installmentNotification.service';
import { newInstalmentDateHelper } from '../helpers/newInstalmentDate.helper';
import { getInstallmentMinDateHelper } from '../helpers/getInstallmentMinDate.helper';
import { isSameDayOrLaterTs } from '../helpers/isSameDayOrLaterTs.helper';

@Component({
  selector: 'app-installments-notification-popup',
  templateUrl: './installments-notification-popup.component.html',
  styleUrls: ['./installments-notification-popup.component.scss'],
})
export class InstallmentsNotificationPopupComponent implements OnInit {

  public readonly MinInstalments = 2;

  public MaxInstalments = 30;

  public readonly MaxInstalmentPiecePaid = 50;

  public mustInstallment: boolean;

  public IsPerInstallmentPiece = false;

  public subscribes: any = {};

  public showCustomPeriod: boolean;

  private paid_amount = 0;

  public discountPercent: number;
  private currentPieceDiscount = 0;
  public discountPaid = false;

  public newInstalments: Array<InstalmentPiece>;
  public minDate: Date = new Date();

  public pieceDiscountMap = [];

  public isDefaultDiscount = false;

  public readonly InstalmentSuccessStatuses: Array<string> = ['Success'];
  public readonly InstalmentProcessStatuses: Array<string> = ['Processing'];

  public instalmentGroup: FormGroup;

  public loader = false;

  public instalmentDetectForm: DetectInstalmentForm | null;

  public isCollectionAgent = false;

  public instalmentData: {
    instalments_methods: Array<{ value: number, label: string }>
    instalments_periods: Array<{ value: number, label: string, js: string }>
    instalments_status: Array<{ value: number, label: string }>
    instalment_pieces_statuses: Array<{ value: number, label: string }>
  } = null;

  public updateInstallment = false;

  public $instalmentCountModel: any = 2;

  public set instalmentCountModel(val: number) {
    this.$instalmentCountModel = val;
  }

  public get instalmentCountModel(): number {
    if (this.instalmentDetectForm) {
      return this.instalmentDetectForm.pieces.length || this.$instalmentCountModel;
    }

    if (this.$instalmentCountModel !== null) {
      return this.$instalmentCountModel;
    }

    return this.instalmentDefaultCount;
  }

  public instalmentDefaultCount = 2;

  private $instalmentMethodModel: any;

  public set instalmentMethodModel(val: number) {
    this.$instalmentMethodModel = val;
  }

  public get instalmentMethodModel(): number {
    if (this.instalmentDetectForm) {
      return this.instalmentDetectForm.instalment.method || this.instalmentDefaultMethod;
    }

    if (this.$instalmentMethodModel !== null) {
      return this.$instalmentMethodModel;
    }

    return this.instalmentDefaultMethod;
  }

  public instalmentDefaultMethod = 0;

  public $instalmentPeriodModel: any;
  public $instalmentCustomPeriodModel: any;

  public set instalmentPeriodModel(val: number) {
    this.$instalmentPeriodModel = val;
  }

  public set instalmentCustomPeriodModel(val: number) {
    this.$instalmentCustomPeriodModel = val;
  }

  public get instalmentPeriodModel(): number {
    if (this.instalmentDetectForm) {
      if (!this.instalmentDetectForm.instalment.period && this.instalmentDetectForm.instalment.period !== 0) {
        return this.instalmentDefaultPeriod;
      } else {
        return this.instalmentDetectForm.instalment.period;
      }
    }

    if (this.$instalmentPeriodModel !== null) {
      return this.$instalmentPeriodModel;
    }

    return this.instalmentDefaultPeriod;
  }

  public get instalmentCustomPeriodModel(): number {
    if (this.instalmentDetectForm) {
      return this.instalmentDetectForm.instalment.period_days || this.instalmentDefaultCustomPeriod;
    }

    if (this.$instalmentCustomPeriodModel !== null && this.$instalmentCustomPeriodModel !== undefined) {
      return this.$instalmentCustomPeriodModel;
    }

    return this.instalmentDefaultCustomPeriod;
  }

  public instalmentDefaultPeriod = 6;
  public instalmentDefaultCustomPeriod = 1;

  public changeAshton(product: string): string {
    switch (product) {
      case 'IELTS Conley University - Retention - Academic Course':
        return 'IELTS Ashton - Retention - Academic Course';
      case 'IELTS Ashton - Retention - Academic Course':
        return 'IELTS Conley University - Retention - Academic Course';
      case 'IELTS Conley University - Retention - General Course':
        return 'IELTS Ashton - Retention - General Course';
      case 'IELTS Ashton - Retention - General Course':
        return 'IELTS Conley University - Retention - General Course';
      default:
        return product;
    }
  }

  public get paymentType(): null | DetectTypeFilter {
    let model = this.data.product;

    if (model === 'IELTS Conley University - Retention - Academic Course' || model === 'IELTS Conley University - Retention - General Course') {
      model = this.changeAshton(model);
    }

    return _get(this.data, ['validatorProducts'], { type: (productName: string) => null }).type(model);
  }

  public get maxProcessPaidAmount(): number {
    return _toNumber(
      this.processLastInstalment ? (_get(this.instalmentDetectForm, ['instalment', 'quote_amount'], 0) || this.data.payment_amount) : (this.data.payment_amount || _get(this.instalmentDetectForm, ['instalment', 'quote_amount'], 0))
    ) -
      this.amountDoneInstalmentsPieces;
  }

  public get doneInstalmentsPieces(): Array<InstalmentPiece> {
    return _sortBy(_get(this.instalmentDetectForm, 'pieces', []).filter((piece: InstalmentPiece): boolean => {
      const statusLabel = _get(_find(this.instalmentData.instalment_pieces_statuses, { value: (piece.status || 0) }), 'label', null);
      return this.InstalmentSuccessStatuses.includes(statusLabel);
    }).map((piece: InstalmentPiece): InstalmentPiece => {
      piece.done = true;
      piece.excessive = false;

      piece.paid_amount = _get(piece, 'paid_amount', _get(piece, 'quote_amount', 0));

      if (!piece.paid_at) {
        piece.paid_at = piece.planned_payment_at;
      }

      return piece;
    }), ['paid_at'], ['asc']);
  }

  public get countDoneInstalmentsPieces(): number {
    return this.doneInstalmentsPieces.length;
  }

  public get amountDoneInstalmentsPieces(): number {
    return this.countDoneInstalmentsPieces > 0 ? _sumBy(this.doneInstalmentsPieces, 'paid_amount') : 0;
  }

  public get OutstandingBalance(): number {

    if (this.discountPercent > 0 && !this.discountPaid && !this.isDefaultDiscount && !this.IsPerInstallmentPiece) {
      return Math.round(this.paymentType.balance * (1 - this.discountPercent));
    }

    return this.paymentType.balance;
  }

  public get diffQuoteAmountInstalments(): number {

    if (this.paymentType && !this.isDefaultDiscount) {
      const paidAmount = this.data.payment_amount - this.paymentType.balance;

      if (this.discountPercent > 0 && !this.discountPaid) {
        return _toNumber(
          paidAmount + this.OutstandingBalance ||
          _get(this.instalmentDetectForm, ['instalment', 'quote_amount'], 0)
        ) - this.amountDoneInstalmentsPieces
      }
    }

    return _toNumber(
      _get(this.instalmentDetectForm, ['instalment', 'quote_amount'], 0) ||
      this.data.payment_amount
    ) - this.amountDoneInstalmentsPieces
  }

  public get diffCountInstalments(): number {
    return _min([
      this.MaxInstalments,
      _max([
        0,
        _toNumber(this.instalmentGroup.get('count').value) -
        this.countDoneInstalmentsPieces
      ])
    ]); // its current instalment
  }

  public get processLastInstalment(): boolean {
    return this.diffCountInstalments === 0;
  }

  public newInstalmentQuoteAmount(isLast = false, sumTillNow = 0): number {
    if (isLast) {
      return _round(_get(this.instalmentDetectForm, ['instalment', 'quote_amount'], 0) - sumTillNow, 2)
    }
    else {
      return _floor(this.diffQuoteAmountInstalments / this.diffCountInstalments, 0)
    }
  }

  public setNewInstalments(recalculatePeriod = false) {
    const instalments = this.doneInstalmentsPieces;
    const isCustomDate = this.instalmentGroup.get('period').value === 6;
    const TIMESTAMP_GAP = 5 * 86400;

    let paidAmountSum = this.amountDoneInstalmentsPieces;

    _range(0, this.diffCountInstalments).forEach(i => {
      const newItem = {
        created_at: 0,
        id: 0,
        payment_id: 0,
        quote_amount: 0,
        status: 0,
        updated_at: 0,
        current: false,
        done: false,
        excessive: this.newInstalmentQuoteAmount() === 0,
        paid_at: null,
        planned_payment_at: null,
        discount: 0,
        paid_amount: this.newInstalmentQuoteAmount(i == this.diffCountInstalments - 1, paidAmountSum),
        additional_data: this.newInstalments && this.newInstalments[instalments.length] && this.newInstalments[instalments.length].additional_data ? this.newInstalments[instalments.length].additional_data : null
      };

      paidAmountSum += newItem.paid_amount;

      if (!newItem.additional_data) {
        newItem.additional_data = this.instalmentDetectForm && this.instalmentDetectForm.pieces && this.instalmentDetectForm.pieces[instalments.length] ? this.instalmentDetectForm.pieces[instalments.length].additional_data : null;
      }

      if (isCustomDate) {
        newItem.paid_at = this.newInstalments && this.newInstalments[instalments.length] ? (this.newInstalments[instalments.length].paid_at || this.newInstalments[instalments.length].planned_payment_at) : null;

        if (!newItem.paid_at) {
          newItem.paid_at = this.instalmentDetectForm && this.instalmentDetectForm.pieces && this.instalmentDetectForm.pieces[instalments.length] ? (this.instalmentDetectForm.pieces[instalments.length].paid_at || this.instalmentDetectForm.pieces[instalments.length].planned_payment_at) : null;
        }
      } else {
        if (this.instalmentGroup.get('period').value !== '0') {
          if (recalculatePeriod) {
            newItem.paid_at = this.newInstalmentDate(0, instalments[instalments.length - 1].paid_at);
          } else {
            newItem.paid_at = this.newInstalments && this.newInstalments[instalments.length] ? (this.newInstalments[instalments.length].paid_at || this.newInstalments[instalments.length].planned_payment_at) : null;

            if (!newItem.paid_at) {
              newItem.paid_at = this.newInstalmentDate(0, instalments[instalments.length - 1].paid_at);
            }
          }
        } else {
          newItem.paid_at = null;
        }
      }

      if (isCustomDate) {
        if (newItem.paid_at && isSameDayOrLaterTs(instalments[instalments.length - 1].paid_at, newItem.paid_at)) {
          newItem.paid_at = instalments[instalments.length - 1].paid_at + TIMESTAMP_GAP;
        }
      } else {
        if (newItem.paid_at && isSameDayOrLaterTs(instalments[instalments.length - 1].paid_at, newItem.paid_at)) {
          newItem.paid_at = this.newInstalmentDate(0, instalments[instalments.length - 1].paid_at)
        }
      }

      if (newItem.paid_amount > 0) {
        instalments.push(newItem);
      }
    });

    this.newInstalments = instalments;
  }

  private $permissionCanUseInstalments = false;

  public get canUseInstalments(): boolean {
    return this.$permissionCanUseInstalments && !!this.instalmentDetectForm;
  }

  public updateInstallments() {
    this.dialog.open(ConfirmModalComponent, {
      width: '500px',
      data: {
        title: 'Update Installment',
        message: `Are you sure you want to update the installment?`,
      }
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.loader = true;

        let body: any = {
          has_installments: true,
        };

        if (this.canUseInstalments) {
          body = Object.assign(body, {
            instalment: {
              is_set: true,
            }
          });

          body = this.getNewInstallmentRequestInfo(body);
        }

        this.installmentService.updateInstallment(body)
          .subscribe((res) => {
            this.loader = false;
            if (res) {
              this.self.close(res);

              this.installmentNotificationService.decrease();
            }
          }, error => {
            this.loader = false;
            if (error.error.error) {
              this.snack.open(error.error.error, 'OK!', {
                duration: 3000
              });
            } else if (error.error.errors) {
              this.snack.open(error.error.errors, 'OK!', {
                duration: 3000
              });
            } else if (error.error.message) {
              this.snack.open(error.error.message, 'OK!', {
                duration: 3000
              });
            } else {
              this.snack.open('Something went wrong', 'OK!', {
                duration: 3000
              });
            }
          });
      }
    });

  }

  constructor(
    private self: MatDialogRef<InstallmentsNotificationPopupComponent>,
    private paymentService: PaymentService,
    private userInfoService: UserInfoService,
    private questionerService: QuestionerService,
    private store: Store<any>,
    private snackBar: MatSnackBar,
    private diplomas: DiplomasService,
    private modal: MatDialog,
    private installmentService: InstallmentService,
    private snack: MatSnackBar,
    private dialog: MatDialog,
    private installmentNotificationService: InstallmentNotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.paid_amount = this.data.paid_amount;

    const localStoreSub = this.store.pipe(select((state) => {
      return localStore.get('settings');
    })).subscribe((u) => {
      this.$permissionCanUseInstalments = ![false, 'no'].includes(_get(u, ['personal', 'can_view_installments_section', 'value'], 'no')) && ![false, 'no'].includes(_get(u, ['personal', 'can_view_installments_section', 'value', 'value'], 'no'));

      if (!this.$permissionCanUseInstalments) {

        this.self.close("You don't have permission to set installments");
      }
    });

    if (localStoreSub) {
      localStoreSub.unsubscribe();
    }

    const storage = this.store.pipe(select((state) => {
      return {
        instalments_methods: state.fields.instalments_methods,
        instalments_periods: state.fields.instalments_periods,
        instalments_status: state.fields.instalments_status,
        instalment_pieces_statuses: state.fields.instalment_pieces_statuses,
      };
    })).subscribe((response) => {
      if (!this.instalmentData) {
        this.instalmentData = response;
      }
    });

    if (this.instalmentData && storage) {
      storage.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.instalmentGroup = new FormGroup({
      create_new: new FormControl(''),
      immediately: new FormControl(''),
      is_set: new FormControl(false),
      has_installments: new FormControl(true),
      period: new FormControl('0'),
      custom_period: new FormControl('1'),
      method: new FormControl('0'),
      count: new FormControl('2', [
        (control: AbstractControl) => {
          let errors = null;

          if (
            this.canUseInstalments &&
            this.instalmentGroup
          ) {
            errors = Validators.max(this.MaxInstalments)(control);

            if (errors !== null) {
              return errors;
            }

            errors = Validators.min(this.MinInstalments)(control);

            if (errors !== null) {
              return errors;
            }

            errors = Validators.required(control);
          }

          return errors;
        },
      ]),
    });

    this.onChanges();

    this.setInstalment();

  }



  onChanges(): void {
    if (this.subscribes.period) {
      this.subscribes.period.unsubscribe();
    }
    this.subscribes.period = this.instalmentGroup.get('period').valueChanges.subscribe((res) => {
      if (res === 5) {
        this.showCustomPeriod = true;
      } else {
        this.showCustomPeriod = false;
      }

      this.newInstalments = null;
      this.setNewInstalments();
    });

    this.subscribes.count = this.instalmentGroup.get('count').valueChanges.subscribe(() => {
      this.setNewInstalments();
    });

    this.subscribes.custom_period = this.instalmentGroup.get('custom_period').valueChanges.subscribe(() => {
      this.setNewInstalments(true);
    });

    this.subscribes.has_installments = this.instalmentGroup.get(
      'has_installments'
    ).valueChanges.pipe(
      startWith(null), pairwise()
    ).subscribe(([prev, next]: [any, any]) => {
      if (prev !== next) {
        const countControl = this.instalmentGroup.get('count');

        if (countControl) {
          countControl.updateValueAndValidity();
        }
        this.setNewInstalments();
        this.markAllAsTouched(this.instalmentGroup);
      }
    });
  }

  markAllAsTouched(group: FormGroup | FormArray) {
    group.markAsTouched({ onlySelf: true });

    Object.keys(group.controls).map((field) => {
      const control = group.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched();
      } else if (control instanceof FormGroup) {
        this.markAllAsTouched(control);
      }
    });
  }

  public getDate(timestamp: number): Date {
    if (!timestamp) { return null; }
    return new Date(timestamp * 1000);
  }

  public getInstallmentMinDate(i: number): Date {
    return getInstallmentMinDateHelper.call(this, i);
  }

  public getPieceStatusLabel(piece: InstalmentPiece): string {
    return _get(_find(this.instalmentData.instalment_pieces_statuses, { value: (piece.status || 0) }), 'label', null);
  }

  public removeInstallmentPiece(idx: number): void {
    const currCount = this.instalmentGroup.get('count').value;

    if (this.newInstalments[idx - 1] && this.newInstalments[idx - 1].current) {
      if (this.newInstalments[idx + 1]) {
        this.newInstalments.splice(idx, 1);
        this.instalmentGroup.get('count').patchValue(currCount - 1);
      } else {
        this.instalmentGroup.get('count').patchValue(currCount - 1);
      }
    } else {
      this.newInstalments.splice(idx, 1);
      this.instalmentGroup.get('count').patchValue(currCount - 1);
    }
  }

  public addNewInstallmentPiece(): void {
    this.instalmentGroup.get('count').patchValue(this.instalmentGroup.get('count').value + 1);
  }

  public isInstallmentsPeriodEmpty(): boolean {
    if (this.newInstalments) {
      if (this.newInstalments.some(i => !i.paid_at)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  public newInstalmentDate(index: number, beginAt: number = _ceil(Date.now() / 1000)): number {
    return newInstalmentDateHelper.call(this, index, beginAt);;
  }

  setInstalment(): void {
    const account_number = this.data.lead_number;

    this.loader = true;
    this.paymentService.detectInstalment(
      this.data.product,
      account_number,
    ).toPromise().then((res: any) => {
      this.loader = false;

      res.pieces = res.instalment.installment_pieces;

      delete res.instalment.installment_pieces;

      this.instalmentDetectForm = res;

      if (!!this.instalmentDetectForm && this.instalmentDetectForm.instalment.product === this.data.product && this.isCollectionAgent) {
        this.instalmentData.instalments_periods = _filter(this.instalmentData.instalments_periods, el => el.value === 5 || el.value === 6 || el.value === this.instalmentDetectForm.instalment.period);
      }

      if (res && this.instalmentDetectForm.discount) {

        if (this.instalmentDetectForm.discount.percent > 0) {
          this.discountPercent = this.instalmentDetectForm.discount.percent;
        }

        if (this.instalmentDetectForm.discount.status === 1) {
          this.discountPaid = true;
        }
        if (this.instalmentDetectForm.discount.slug === 'default') {
          this.isDefaultDiscount = true;
        }

        if (this.instalmentDetectForm.discount.slug === 'per_installment_piece') {
          this.IsPerInstallmentPiece = true;
          if (this.instalmentDetectForm.discount.details && this.instalmentDetectForm.discount.details.installment_pieces) {
            this.instalmentDetectForm.discount.details.installment_pieces.map(d => {
              this.pieceDiscountMap.push(d.discount);
            });
          }
        }

      }

      if (res && res.pieces && res.pieces.length && res.instalment.status !== 0 && res.pieces[0].status !== 2) {
        this.instalmentGroup.get('custom_period').markAsTouched();
        if (!this.isCollectionAgent && res.instalment.is_set) {
          this.instalmentGroup.get('custom_period').disable();
        }
      } else if (!res) {
        this.instalmentGroup.get('custom_period').enable();
      }

      if (res && res.instalment) {
        if (res.instalment.period === 5) {
          this.showCustomPeriod = true;
        } else {
          this.showCustomPeriod = false;
          this.$instalmentPeriodModel = res.instalment.period;
        }
      } else {
        this.showCustomPeriod = false;
        this.$instalmentPeriodModel = 6;
      }

      let newCountValue = this.doneInstalmentsPieces.length + this.diffCountInstalments;

      this.instalmentGroup.get('is_set').patchValue(res.instalment.is_set);
      this.instalmentGroup.get('count').patchValue(newCountValue);
      this.instalmentGroup.get('period').patchValue(this.instalmentPeriodModel);

      if (this.instalmentGroup.get('period').value === 5) {
        if (res.pieces && res.pieces.length > 1) {
          this.instalmentGroup.get('custom_period').patchValue(Math.floor((res.pieces[1].planned_payment_at - res.pieces[0].planned_payment_at) / (3600 * 24)));
          this.$instalmentCustomPeriodModel = this.instalmentGroup.get('custom_period').value;
          this.instalmentDefaultCustomPeriod = this.instalmentGroup.get('custom_period').value;
        }
      }

      this.newInstalments = null;
      this.setNewInstalments();

      for (let i = 0; i < this.newInstalments.length; i++) {
        if (this.newInstalments[i].current) {
          if (this.pieceDiscountMap.length > i && this.newInstalments[i].discount > 0) {
            this.currentPieceDiscount = this.pieceDiscountMap[i];
            break;
          }
        }
      }

      this.subscribes.installment_filled = this.instalmentGroup.valueChanges.pipe(startWith(null), pairwise()).subscribe(([prev, next]: [any, any]) => {
        if (prev && JSON.stringify(prev) !== JSON.stringify(next)) {
          this.updateInstallment = true;
        }
      });

      setTimeout(() => {
        const el = document.getElementById('fake-click');
        if (el !== null) {
          el.click();
        }
      }, 100);
    });
  }

  dateInstallmentChanged(index: number, event: any) {
    var now = new Date();

    this.updateInstallment = true;

    const installmentDate = new Date(event.target.value)

    // Set the hour and minute to the current hour and minute
    installmentDate.setHours(now.getHours());
    installmentDate.setMinutes(now.getMinutes() > 0 ? Math.round(now.getMinutes() / 15) * 15 : 0);

    this.newInstalments[index].paid_at = installmentDate.getTime() / 1000;

    this.setNewInstalments();
  }

  private getNewInstallmentRequestInfo(requestBody: any): object {
    requestBody.instalment = Object.assign(requestBody.instalment, {
      id: _toInteger(_get(this.instalmentDetectForm, ['instalment', 'id'], 0)),
      create_new: Boolean(_toInteger(_get(this.instalmentDetectForm, ['instalment', 'id'], 0) <= 0)),
      // immediately: Boolean(this.instalmentGroup.get('immediately').value),
      period: _toInteger(this.instalmentGroup.get('period').value),
      count: _toInteger(this.instalmentGroup.get('count').value),
    });

    if (this.newInstalments.length > _toInteger(this.instalmentGroup.get('count').value)) {
      requestBody.instalment.count = this.newInstalments.length;
    }

    requestBody.instalment.custom_period_dates = [];

    this.newInstalments.map(installment => {
      requestBody.instalment.custom_period_dates.push(_ceil(DateConverter.convertToUTC(new Date(installment.paid_at * 1000)).getTime() / 1000));
    });


    if (this.updateInstallment && requestBody.instalment.id) {
      requestBody.instalment.update = true;
    }

    this.pieceDiscountMap.splice(requestBody.instalment.count);

    requestBody.instalment.discounts = this.pieceDiscountMap;

    return requestBody;
  }

}



