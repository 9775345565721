export function getInstallmentMinDateHelper(i: number): Date {
    if (this.newInstalments && this.newInstalments[i - 1] && this.newInstalments[i - 1].paid_at) {
        const date = this.getDate(this.newInstalments[i - 1].paid_at);

        if (this.newInstalments[i - 1].done) {
            date.setDate(date.getDate());
        } else {
            date.setDate(date.getDate() + 1);
        }

        return date;
    } else {
        return this.minDate;
    }
}