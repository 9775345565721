import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs/Rx';

@Injectable({
    providedIn: 'root'
})
export class CheckinService {

    constructor(private api: ApiService) {
    }

    public checkIn(body): Observable<any> {
        return this.api.post('/dialer/check-in', {
            body: body
        });
    }

    public checkOut(body): Observable<any> {
        return this.api.post('/dialer/check-out', {
            body: body
        });
    }

    public checkInSip(body): Observable<any> {
        return this.api.post('/dialer/check-in-sip', {body});
    }

    public checkOutSip(body): Observable<any> {
        return this.api.post('/dialer/check-out-sip', {body});
    }
    public checkInOpenTok(): Observable<any> {
        return this.api.post('/dialer/check-in-opentok', {});
    }

    public checkOutOpenTok(): Observable<any> {
        return this.api.post('/dialer/check-out-opentok', {});
    }

    public getAvailableCallSystems(): Observable<any> {
        return this.api.get('/dialer/available-call-systems', {});
    }
}
