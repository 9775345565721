import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationPopupModalComponent } from './notification-popup-modal.component';
import { MatButtonModule } from '@angular/material';
import {RichTextEditorModule} from '@syncfusion/ej2-angular-richtexteditor';
import {NgbCarouselModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [NotificationPopupModalComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    RichTextEditorModule,
    NgbCarouselModule
  ],
  exports: [
    NotificationPopupModalComponent
  ],
  entryComponents: [
    NotificationPopupModalComponent
  ]
})
export class NotificationPopupModalModule { }
