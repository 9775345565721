export default class Utils {
  static toTitleCase(str: any): any {
    if (typeof str !== 'string') {
      return str;
    }
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  /**
   * Returns true if value is:
   * - `null`
   * - `undefined`
   * - a zero-length array
   * - a primitive object without keys
   */
  static isEmpty(val: any): boolean {
    return (
      typeof val === "undefined" ||
      val === null ||
      val === "" ||
      ([Object, Array].includes(val.constructor) &&
        Object.keys(val).length === 0)
    );
  }
}
